import React from 'react'
import Layout from '../components/layout2'
import Container from '../components/container'
import Vbox from '../components/vbox'
import Top from '../components/top'
import Summary from '../components/summary'
import Button from '../components/button'
import Img from '../components/img'

class RootIndex extends React.Component {
  render() {
 
    return <Layout>
      
      <Container>
        <Vbox widegap>
          <Top 
            title="Redesign av Jagharläst.se"
            body="Analys och redesign av Sveriges största portal för bokrecensioner skrivna av barn."
            image="/jhl.png"
          />
          <div>
            <h2 className="h2">1 - bakgrund & översikt: </h2>
            <Container narrow>
              <h3 className='h3'>Jagharläst.se & LegiLexi</h3>
              <p className="body">Sajten jagharlast.se är skapad för att ge lärare och elever en gemensam plattform för att öka läsandet på fritiden och framförallt uppmuntra eleverna till lustfyllt läsning under loven. </p>
              <p className="body">På jagharläst.se kan eleverna idag skriva och skicka in bokrecensioner och göra lovutmaningar.</p>
              <p className="body">Jagharläst.se drivs av den icke-vinstdrivande stiftelsen LegiLexi.</p>
            </Container>
          </div>
          <Summary>
            <h4 className="h4">Roll</h4>
            <p className="small">UX/UI designer praktikant</p>
            <h4 className="h4">Uppdrag</h4>
            <p className="small">Genomföra en UX-analys av jagharläst.se och förbättra en utvald funktion (bokrecension) för att optimera användarupplevelsen.</p>
            <h4 className="h4">Arbetsuppgifter</h4>
            <p className="small">- Heuristisk utvärdering <br />
  - User Research <br />
  - Facilitera workshop för idégenerering  <br />
  - Skisser och wireframes  <br />
  - Användningstest  <br />
  - Använda och lägga till komponenter i designsystem
  HiFi prototyp i Figma.</p>
  <h4 className="h4">Resultat</h4>
            <p className="small">
              - Flera high impact/Low effort-fixar som snabbt kunde implementeras för en förbättrad användarupplevelse. <br />
  - Ett nytt gränssnitt för landningssidan, elevinlogg samt ett nytt flöde för att skriva & skicka in en bokrecension som ska implementeras 2024. <br />
  - Förslag på nya visuella element</p>
  <Button to="https://www.figma.com/proto/9OFTtKmSMw3qeNtZmwZDXj/Frida-Runnakko---Portfolio?node-id=741-34188&node-type=canvas&t=LHCSj790oKD9uV3A-8&scaling=min-zoom&content-scaling=fixed&page-id=490%3A1406&starting-point-node-id=741%3A34188&show-proto-sidebar=1&disable-default-keyboard-nav=1&hide-ui=1&fuid=1159438344668895628">
    Länk till prototyp {"->"}
  </Button>
          </Summary>
          <div>
            <h2 className="h2">2 - resultat:</h2>
            <Container narrow>
              <h3 className='h3'>Prototyp</h3>
              <h4 className='h4'>Hifi-prototyp</h4>
              <p className="body">Designen baserades på det befintliga designsystemet, men jag gjorde vissa justeringar och lade till nya komponenter för att förbättra användarupplevelsen.</p>
              <Img src="/jhl1.png" caption="Flöde för bokrecension HiFi" />
              <Img src="/jhl2.png" caption="Landningssida HiFi" />
              <Button to="https://www.figma.com/proto/9OFTtKmSMw3qeNtZmwZDXj/Frida-Runnakko---Portfolio?node-id=741-34188&node-type=canvas&t=LHCSj790oKD9uV3A-8&scaling=min-zoom&content-scaling=fixed&page-id=490%3A1406&starting-point-node-id=741%3A34188&show-proto-sidebar=1&disable-default-keyboard-nav=1&hide-ui=1&fuid=1159438344668895628">
                Länk till prototyp {"->"}
              </Button>
            </Container>
          </div>
          
          
          <div>
            <h2 className="h2">3 - processen:</h2>
            <Container narrow>
              <h3 className='h3'>Discovery</h3>
              <h4 className='h4'>Bakgrund</h4>
              <p className="body">De nuvarande användarna av jagharläst.se består främst av elever på skolor som även tillämpar Legilexis verktyg för att följa elevers läsutveckling, och antalet användare ökar stadigt varje år. Detta tyder på en potential för att användningen av jagharläst.se också kan öka. Sajten har inte uppdaterats på flera år, vilket har resulterat i en föråldrad design samt bristande funktionalitet och användarvänlighet.</p>

              <h4 className='h4'>Scope</h4>
              <p className="body">Eleverna kan både skriva bokrecension och göra lovutmamingar på jagharläst.se. Efter researcharbetet prioriterades flödet för bokrecension då det är starkare kopplat till syftet & målet med jagharläst.se samt att det var det flödet som orsakade flest painpoints.</p>
              <h4 className='h4'>Research-mål</h4>
              <p className="body">Få en förståelse för vad som motiverar lågstadieeleverna till att läsa och skriva bokrecensioner & förstå vad de stöter på för hinder på jagharläst.se bokrecension idag.</p>
              <h4 className='h4'>Intervjuer & enkät</h4>
              <p className="body">Jag gjorde 3 användarintervjuer och skickade ut en enkät till som besvarades av 76 lärare och föräldrar till lågstadieelever som använder jagharläst.se som besvarade frågor om vad som motiverar eleverna till att läsa och skriva bokrecension.</p>
              
              <Img src="/jhl3.png" caption="Fyra teman som utkristalliserades kopplade till att skriva bokrecension" />
              <h4 className='h4'>Insikter</h4>
              <p className="body">Jag fann fyra teman kopplade till att skriva bokrecension på jagharläst.se där det första temat “se resultat” var det som nämndes flest gånger. </p>
              <ol>
                <li>Se resultat</li>
                <li>Tävling & belöning</li>
                <li>Visa & dela</li>
                <li>Rätt nivå</li>
              </ol>
              <h4 className='h4'>Heuristisk utvärdering, användnigstest & beteendeanalys med heatmaps</h4>
              <p className="body">Jag gjorde en heuristisk utvärderning för att snabbt kunna identifiera potentiella problem i användarupplevelsen, gjorde ett användningstest med en lågstadieelev samt analyserade beteenden utifrån heatmaps som jag sammanställde i en användarresa.</p>
              <Img narrow src="/jhl4.png" caption="Användarresa baserad på insikter från research" />
              <h4 className='h4'>Insikter</h4>
              <p className="body">Majoriteten av painpointsen uppstod i “skriva-fasen”</p>
              <ul>
                <li>Att inte kunna backa ett steg i taget</li>
                <li>Svårt att söka upp och hitta rätt bok</li>
                <li>Får ingen hjälp att rätta till fel (exempelvis ett obligatoriskt fält som lämnats oifyllt)</li>
                <li>Vissa frågor är formulerade med för komplicerade ord</li>
                <li>Svårt att få en överblick över sin bokrecension</li>
                <li>Otillfredställande och otydlig feedback när recensionen skickats in</li>
              </ul>
            </Container>
          </div>
          <div>
            <Container narrow>
              <h3 className='h3'>Prioritering och idégenerering</h3>
              <h4 className='h4'>Workshop</h4>
              <p className="body">Efter att ha samlat in data och insikter från användarna var det nödvändigt för mig att prioritera och utveckla tekniskt genomförbara lösningar. För att åstadkomma detta faciliterade jag en idégenereringsworkshop med produktägare, projektledare och utvecklare, där vi utgick från How Might We-frågor som formulerats baserat på de insikter och problem som framkommit under researchfasen. </p>
              <h4 className='h4'>Resultat av workshop</h4>
              <p className="body">De lösningsförslag som röstats fram av deltagarna fick prioriteras i en high-impact/low-effort matris, de förslag som hamnade i 1a kvadranten kunde jag ta med till skissbordet.</p>
              <Img narrow src="/jhl5.png" caption="Geniknölar som gnuggas i workshopen" />
              <Img narrow src="/jhl6.png" caption="Lösningsförslag i en effort/impact matris" />
            </Container>
          </div>
          
          <div>
            <Container narrow>
              <h3 className='h3'>Skiss & design</h3>
              <h4 className='h4'>Handritade skisser och wireframes</h4>
              <p className="body">Jag är en stark förespråkare av handskisser, jag tycker det är ett lätt sätt att testa idéer där det är mindre risk att fastna i detaljer. i Detta projekt gjorde jag först många snabba handskisser för att sedan rita upp wireframes i Figma med regelbundna feedback loopar.</p>
              
              <Img narrow src="/jhl7.png" caption="Snabba handskisser på elevsida och recensionsflöde" />
              <Img narrow src="/jhl8.png" caption="Wireframes på elevsida och flödet för bokrecension" />
            </Container>
          </div>

          
          <div>
            <h2 className="h2">4 - summering:</h2>
            <Container narrow>
              <h3 className='h3'>Insikter & lärdomar</h3>
              <h4 className='h4'>Avgränsning & prioritering</h4>
              <p className="body">Man vill ofta göra väldigt mycket, och det blir lätt för mycket. Min ingångspunk i projektet var att “göra om hela sidan” vilket också var den initiala beskrivningen från uppdragsgivaren, men jag insåg att det blev lite för brett och för stort för den begränsade tiden jag hade. Det resulterade i att jag tog ett lite för stort grepp från början när jag istället borde ha gjort många användningstester. Det hjälpte därför att avgränsa mig och koncentrera mig på flödet för att skriva bokrecension.</p>
              <h4 className='h4'>Få in fler perspektiv</h4>
              <p className="body">Det var otroligt givande att få in fler perspektiv och förslag på lösningar från både projektledarsidan och utvecklare för att få en förståelse för vad som är tekniskt och ekonomiskt genomförbart.</p>
              <h4 className='h4'>Testa oftare</h4>
              <p className="body">Design måste alltid itereras för att förbättras för slutanvändaren. Jag hade önskat att jag hunnit testa med fler användare för att verifiera min design.</p>
            </Container>
          </div>
          
          <div>
            <h2 className="h2">fler projekt:</h2>
            <div className='more-wrapper'>
              <div class="more">
                <img src='/forum.png' />
                <h3 className='h3'>Bibliotek Forum</h3>
                <Button to="/forum">Utforska hela projektet {"->"}</Button>
              </div>
              <div class="more">
                <img src='/roder.png' />
                <h3 className='h3'>Internverktyg för Roder Innovation</h3>
                <Button to="/roder">Utforska hela projektet {"->"}</Button>
              </div>
            </div>
          </div>
        </Vbox>
      </Container>
    </Layout>
    
    
  }
}

export default RootIndex